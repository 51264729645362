$primary-color: red;
$secondary-color: rgb(231, 231, 231);
$primary-color-dark: rgb(165, 0, 0);
$secondary-color-dark: rgb(71, 71, 71);

.application-container {
  height: 100vh;
}

.application-header {
  text-align: center;
  color: white;
}

.autocomplete-item {
  z-index: 30;
}

.autocomplete-input {
  max-width: 250px;
  min-width: 100px;
}

label {
  width: 10%;
}

.dark {
  &.application-header {
    background-color: $primary-color-dark;
  }

  &.input-form {
    background-color: $secondary-color-dark;
    color: white;
  }
  
  &.departure-table {
    background-color: $secondary-color-dark;
    color: white;
  }

  &.autocomplete-item {
    background: $secondary-color-dark;

    &.highlighted {
      background: $primary-color-dark;
    }
  }
}

.light {
  &.application-header {
    background-color: $primary-color;
  }

  &.input-form {
    background-color: $secondary-color;
  }

  &.departure-table {
    background-color: $secondary-color;
  }

  &.autocomplete-item {
    background: $secondary-color;

    &.highlighted {
      background: $primary-color;
    }
  }
}